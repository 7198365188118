:<template>
  <vx-card class=" top-zero-radius" :class="{'w-full': inModal, 'md:w-1/2 main-box p-2': !inModal}">
    <custom-validate-input class="w-full"
                           :label="`${$t('treasury.cashBoxes.labels.name')}`"
                           :autocomplete="false"
                           :in-valid="$v.new_cash_box.name.$invalid"
                           :name="Math.random()"
                           @keydown.enter="sendData"
                           v-model="new_cash_box.name"/>

    <vs-button id="insertCashBoxBTN"
               class="useral-action-button"
               @click="sendData()"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {insertCashBoxes} from '@/http/requests/cashBoxes'
import CustomValidateInput from "../../../../../components/customInput/customValidateInput.vue";
import {required} from "vuelidate/lib/validators";

export default {
  name: 'insertCashBox',
  components: {CustomValidateInput},
  metaInfo () {
    return {
      title: this.$t('treasury.cashBoxes.insert.title')
    }
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      new_cash_box: {
        name: {
          value: '',
          isValid: true
        }
      },
      actions: [
        {
          toolbar: [
            {
              id: 'insertCashBoxBTN',
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success',
              permission: 'cash.create'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'treasuryCashBoxes'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  validations: {
    new_cash_box: {
      name: {
        value: {
          required
        }
      }
    }
  },
  created () {
    if (!this.inModal) {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        if (!this.inModal) {
          this.$store.dispatch('updateContentNavbarStyle', 'sticky')
          this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
        }
      })
    }
  },
  methods: {
    sendData () {
      if (this.$v.new_cash_box.$invalid) return false
      const cashBox = {
        name: this.new_cash_box.name.value
      }
      insertCashBoxes(cashBox).then((response) => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.cashBoxes.notifications.insert.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        this.$store.dispatch('removePageChanges')
        if (!this.inModal) {
          this.$router.push({name: 'treasuryCashBoxes'})
        } else {
          this.$emit('inserted', response.data.data)
        }
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cashBoxes.notifications.insert.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
