// =============================================================================
// HOME Routes
// =============================================================================

export default {
  path: '/',
  name: 'home',
  component: () => import(/* webpackChunkName: "home" */ '../../views/admin/Home.vue'),
  meta: {
    pageTitle: 'داشبورد',
    pageTitleI18n: 'dashboard'
  }
}
