export default {
  setUserBrowserSettings ({commit}, payload) {
    commit('SET_USER_BROWSER_SETTING', payload)
  },
  setTableSetting ({commit}, payload) {
    commit('SET_TABLE_SETTING', payload)
  },
  updateUserBrowserSettings ({commit}) {
    commit('UPDATE_USER_BROWSER_SETTINGS')
  }
}
