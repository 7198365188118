// =============================================================================
// COMPANY PAGES LAYOUTS
// =============================================================================

export default {
  path: 'companies',
    component: () => import('../../views/admin/companies/index.vue'),
  children: [
  {
    path: '/',
    name: 'companies',
    component: () => import(/* webpackChunkName: "companies" */ '../../views/admin/companies/list/companiesList.vue'),
    meta: {
      pageTitle: 'لیست شرکت ها',
      pageTitleI18n: 'companies.title'
      // rule: 'editor'
    }
  },
  {
    path: 'insert',
    name: 'insertCompany',
    component: () => import(/* webpackChunkName: "insertCompany" */ '../../views/admin/companies/insert/insertCompany.vue'),
    meta: {
      pageTitle: '',
      pageTitleI18n: 'companies.insert.title'
      // rule: 'editor'
    }
  },
  {
    path: ':id',
    name: 'company',
    component: () => import(/* webpackChunkName: "company" */ '../../views/admin/companies/company/company.vue'),
    meta: {
      pageTitle: 'شرکت',
      pageTitleI18n: 'companies.company.title'
      // rule: 'editor'
    }
  },
  {
    path: ':id/edit',
    name: 'editCompany',
    component: () => import(/* webpackChunkName: "editCompany" */ '../../views/admin/companies/edit/editCompany.vue'),
    meta: {
      pageTitle: '',
      pageTitleI18n: 'companies.edit.title'
      // rule: 'editor'
    }
  }
]
}
