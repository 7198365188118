import Vue from 'vue'
import { setValidator } from "./validators/validator";
import { loading } from './loading'
import { modal } from './modal'
import { cookie } from './cookie'
import { locale } from './locale'
import { numberToWord } from './numberToWord'
import {
  getAssetsUrl,
  customParseFloat,
  click,
  numberFormat,
  priceFormat,
  checkPermissions,
  convertUtcToLocale,
  convertLocaleToUtc,
  convertPersianNumberToEnglish,
  time,
  trim,
  addSpace,
  exportFormat,
  resizeTextOverflow
} from './other'

const index = {
  getAssetsUrl,
  parseFloat: customParseFloat,
  click,
  loading,
  modal,
  cookie,
  locale,
  numberFormat,
  priceFormat,
  checkPermissions,
  time,
  trim,
  addSpace,
  validator: setValidator,
  numberToWord,
  convertUtcToLocale,
  convertLocaleToUtc,
  convertPersianNumberToEnglish,
  exportFormat,
  resizeTextOverflow
}

Vue.prototype.$u = index
