<template>
  <div class="products-opening-balance">
    <draggable-dynamic-table ref="productsOpeningBalance"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             :suggests="suggests"
                             @search="suggests.name = [], setFilter($event)"
                             @search:barcode="searchProductWithBarcode($event)"
                             @goto:price="goToColumn('price', $event)"
                             @goto:newRow="goToColumn('newRow', $event)"
                             @dropDown:selected="/*handleStoreroomSelected($event)*/"
                             @row:deleted="handleDeleteRow($event)"
                             @suggest:selected="handleInsertNewRow"
                             @newSuggestPrompt:opened="newSuggestProductPromptStatus = true, rowIndex = $event"
                             @suggestPrompt:opened="suggestPromptStatus = true, rowIndex = $event"
                             v-model="data"/>

    <!-- products list prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="suggestPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('selectProducts')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('products.openingBalance.productList') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="suggestPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <select-products :opening-balance="storeroomId"
                             type="all"
                             :storeroom-id="storeroomId"
                             @prompt:reload="handleReloadPrompt()"
                             @selected="handleSuggestSelected($event, rowIndex)"/>
          </keep-alive>
        </template>
        <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
      </div>

    </vs-prompt>

    <!-- insert new product prompt -->
    <vs-prompt
      class="big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="newSuggestProductPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('InsertNewProductBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('products.openingBalance.labels.insertProduct') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="newSuggestProductPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-product :in-modal="true" @inserted="handleNewProductInserted($event)"/>
          </keep-alive>
        </template>
        <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
      </div>

    </vs-prompt>

    <!-- import opening product prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="importDataPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleSendImportedFile">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('products.openingBalance.labels.importData') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="importDataPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content px-2">
        <template>
          <keep-alive>
            <custom-file-input :label="$t('products.insert.labels.importData')"
                               accept-type=".csv"
                               logo-type="formData"
                               :preview="false"
                               v-model="importedDataFile"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button v-show="false" id="importDataBTN" @click="importDataPromptStatus = true"/>
    <button v-show="false" id="saveBTN" @click="sendData"/>
  </div>
</template>

<script>
import axios from 'axios'
import {getInStockShreddedProducts} from '@/http/requests/products'
import {setStoreroomsOpeningInventories} from '@/http/requests/openingInventories'
import SelectProducts from '@/views/admin/products/productsList/select/selectProducts'
import InsertProduct from '@/views/admin/products/productsList/insert/insertProduct'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import {importStoreroomsOpeningInventories} from '../../../../../http/requests/openingInventories'
import CustomFileInput from "../../../../../components/customInput/customeFileInput";

export default {
  name: 'insertProductsOpeningBalance',
  components: {CustomFileInput, CustomIcon, InsertProduct, SelectProducts},
  props: {
    storeroomId: {
      type: [Number, String],
      default: 0,
      validator (value) {
        return parseInt(value || 0) >= 0
      }
    }
  },
  data () {
    return {
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'delete',
          i18n: 'products.openingBalance.table.header.delete',
          width: '70px',
          minWidth: 70,
          /*sortable: true,*/
          actions: true,
          showAction: 'always',
          action: {
            color: 'danger',
            icon: 'TRASH',
            iconPack: 'useral',
            type: 'button'
          },
          event: 'row:deleted'
        },
        {
          field: 'totalPrice',
          i18n: 'products.openingBalance.table.header.totalPrice',
          hideCurrency: true,
          width: '150px',
          minWidth: 150,
          /*sortable: true,*/
          /*editable: true,*/
          align: 'right',
          type: 'relation',
          relation: '(count)[*](unitPrice)',
          relationType: 'price',
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'unitPrice',
          i18n: 'products.openingBalance.table.header.unitPrice',
          hideCurrency: true,
          width: '150px',
          minWidth: 150,
          align: 'right',
          /*sortable: true,*/
          valueEnterKeyUpEvent: 'goto:newRow',
          editable: true,
          returnValue: false,
          valueType: 'price'
        },
        {
          field: 'count',
          i18n: 'products.openingBalance.table.header.count',
          width: '70px',
          minWidth: 70,
          returnValue: false,
          align: 'center',
          valueEnterKeyUpEvent: 'goto:price',
          /*sortable: true,*/
          editable: true
        },
        {
          field: 'variant',
          i18n: 'products.openingBalance.table.header.variant',
          width: 'calc(25%)',
          minWidth: 150
          /*sortable: true,*/
          // dropDown: true
        },
        {
          field: 'name',
          i18n: 'products.openingBalance.table.header.name',
          align: 'left',
          width: 'calc(75%)',
          minWidth: 500,
          /*sortable: true,*/
          editable: true,
          returnValue: false,
          suggest: true,
          suggestPrompt: true
          /*suggestPromptTitle: 'لیست کالاها',
          suggestPromptComponent: 'products'*/
        },
        {
          field: 'barcode',
          i18n: 'products.openingBalance.table.header.barcode',
          align: 'center',
          width: '150px',
          minWidth: 150,
          editable: true,
          returnValue: false,
          valueEnterKeyUpEvent: 'search:barcode',
          valueType: 'number',
          footer: {}
        },
        {
          field: 'rowNumber',
          i18n: 'products.openingBalance.table.header.rowNumber',
          align: 'center',
          width: '70px',
          minWidth: 70,
          fixed: true,
          footer: {}
        }
      ],
      data: [],
      productsList: [],
      filters: {},
      suggests: {
        name: []
      },
      loadingTimer: 0,
      total_count: 0,
      page: 1,
      selectedFile: null,
      importedDataFile: {
        value: '',
        isValid: true
      },
      suggestPromptStatus: false,
      newSuggestProductPromptStatus: false,
      importDataPromptStatus: false
    }
  },
  created () {
    this.getProducts()
    this.checkOpeningBalanceStatus()
    this.handleInsertNewRow()
  },
  methods: {
    getProducts () {
      // get products for suggests
      getInStockShreddedProducts(this.storeroomId, 1, this.filters).then((response) => {
        const products = response.data

        products.data.forEach((product) => {
          const variables = product.variables.map(elm => {
            return `${elm.name.replaceAll('-', ' ')}: ${elm.value.replaceAll('-', '/')}`
          })
          const data = {
            label: `${product.product.title}${variables.length > 0 ? ` - ${  variables.join(', ')}` : ''}`,
            value: {
              id: product.id,
              barcode: product.barcode,
              code: product.product.code,
              name: product.product.title,
              variant: variables.length > 0 ? variables.join(', ') : '-',
              category: product.product.category.id === 4 ? this.$t('products.labels.withoutCategory') : product.product.category.name,
              type: product.product.type === 1 ? 'کالای ساده' : product.product.type === 2 ? 'کالای متغیر' : 'خدمت',
              count: 0,
              unitPrice: 0,
              totalPrice: {value: 0, type: 'price'},
              flag: 'NEW'
            }
          }
          this.suggests.name.push(data)
        })
        this.productsList = this.suggests.name
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        }
      })
    },
    getSuggestList () {
      const products = JSON.parse(JSON.stringify({data: this.productsList}))
      const items = this.data.map((elm) => {
        return elm.id
      })
      const list = []

      products.data.forEach((user) => {
        if (items.indexOf(user.value.id) === -1) {
          list.push(user)
        }
      })

      this.suggests.name = JSON.parse(JSON.stringify(list))
    },
    handleNewProductInserted (product) {
      this.newSuggestProductPromptStatus = false
    },
    handleSuggestSelected (list, index) {
      const rows = JSON.parse(JSON.stringify(this.data))

      const null_row_index = rows.map(elm => {
        return elm.id
      }).filter(id => {
        return id !== null
      }).indexOf(0)
      if (null_row_index >= 0) rows.splice(null_row_index, 1)

      let i = 0
      list.forEach((data) => {
        if (!data.hasOwnProperty('show') || data.show) {
          i++
          const newData = {}
          this.columnsLabel.forEach((column) => {
            if (data.hasOwnProperty(column.field)) newData[column.field] = data[column.field]
            else newData[column.field] = ''
          })
          newData.id = data.id
          newData.variant = data.variant
          newData.flag = 'New'
          newData.count = 0
          newData.unitPrice = /*data.unitPrice.value*/ 0
          newData.totalPrice = {
            type: 'price',
            value: /*data.unitPrice.value*/ 0
          }

          if (data.hasOwnProperty('id')) {
            newData.id = data.id
            const isExist = rows.map((elm) => {
              return elm.id
            }).indexOf(data.id) > -1
            if (isExist) {
              let text = this.$t('products.openingBalance.validators.valueExist')
              if (newData.name) text = this.$t('products.openingBalance.validators.productExist', {name: newData.name})
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text,
                time: 5000,
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            } else if (i === 1 && index >= 0) {
              newData.rowNumber = index + 1
              rows[index] = newData
            } else {
              newData.rowNumber = rows.length + 1
              rows.push(newData)
            }
          } else if (i === 1 && index >= 0) {
            newData.rowNumber = index + 1
            rows[index] = newData
          } else {
            newData.rowNumber = rows.length + 1
            rows.push(newData)
          }
        }
      })

      this.suggestPromptStatus = false
      this.data = rows
      this.$store.dispatch('setPageChanges')
      this.handleInsertNewRow()
    },
    handleSuggestUserSelected (list) {
      let user = {}
      list.forEach((data) => {
        if (!data.hasOwnProperty('show') || data.show) {
          user = {
            id: data.id,
            name: {
              value: data.name,
              isValid: true
            }
          }
        }
      })
      this.newInvoice.user = user
      this.suggestUserPromptStatus = false
    },
    handleInsertNewRow () {
      if (!this.$store.state.helper.openingBalanceLocked) {
        this.getSuggestList()

        const checkName = this.data.map((elm) => {
          return elm.name === ''
        }).indexOf(true) > -1
        if (!checkName) {
          this.data.push({
            id: 0,
            code: '',
            name: '',
            variant: '',
            count: 0,
            unitPrice: 0,
            totalPrice: {
              type: 'price',
              value: 0
            },
            storeroom: {
              selected: {
                label: '-',
                value: '-'
              }
            },
            flag: 'NEW'
          })
        }
      }
    },
    handleDeleteRow (row) {
      if (row) {
        const index = this.data.map((elm) => {
          return elm.id
        }).indexOf(row.id)
        if (index > -1 && row.id !== 0) {
          this.data.splice(index, 1)
          /*this.data[index].flag = 'UPDATED'
          this.data[index].price = 0*/
          setTimeout(() => {
            this.getSuggestList()
          }, 100)
        }
      }
    },
    searchProductWithBarcode (rowData) {
      clearTimeout(this.isTypingTimer)
      this.isTypingTimer = setTimeout(() => {
        const rowIndex = rowData.index
        if (rowData.data) {
          const filters = {
            barcode: `${rowData.data},1`,
            openingInventory: `${this.storeroomId}`
          }
          getInStockShreddedProducts(this.storeroomId, 1, filters).then(response => {
            const product = response.data.data[0]

            const variables = product.variables.map(elm => {
              return `${elm.name.replaceAll('-', ' ')}: ${elm.value.replaceAll('-', '/')}`
            })

            if (product && this.data.length > rowIndex) {
              const rows = JSON.parse(JSON.stringify(this.data))
              if (rows[0].hasOwnProperty('id') && (rows[0].id === 0 || rows[0].id === '')) {
                rows.splice(0, 1)
              }
              const isExist = rows.map((elm) => {
                return elm.id
              }).indexOf(product.id) > -1
              if (isExist) {
                let text = this.$t('products.openingBalance.validators.valueExist')
                if (product.product.title) text = this.$t('products.openingBalance.validators.productExist', {name: product.product.title})
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text,
                  time: 5000,
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
              } else {

                this.data[rowIndex] = {
                  id: product.id,
                  rowNumber: this.data.length > rowIndex + 1 ? (this.data[rowIndex + 1].rowNumber + 1) : this.data.length,
                  barcode: product.barcode,
                  code: product.product.code,
                  name: product.product.title,
                  variant: variables.length > 0 ? variables.join(', ') : '-',
                  category: product.product.category.id === 4 ? this.$t('products.labels.withoutCategory') : product.product.category.name,
                  type: product.product.type === 1 ? this.$t('products.openingBalance.productType.simple') : product.product.type === 2 ? this.$t('products.openingBalance.productType.variant') : this.$t('products.openingBalance.productType.service'),
                  count: 0,
                  unitPrice: 0,
                  totalPrice: {value: 0, type: 'price'},
                  flag: 'NEW'
                }
                setTimeout(() => {
                  this.$refs.productsOpeningBalance.$refs[`table_row_${product.id}`][0].$refs['input_count'][0].$el.firstElementChild.focus()
                }, 50)
              }
              this.handleInsertNewRow()
              this.$store.dispatch('setPageChanges')
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('products.openingBalance.validators.barcode'),
                time: 5000,
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          }).catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('products.openingBalance.validators.barcode'),
                time: 5000,
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
        }
      }, 100)
    },
    goToColumn (type, rowData) {
      clearTimeout(this.isTypingTimer)
      this.isTypingTimer = setTimeout(() => {
        switch (type) {
        case 'price':
          setTimeout(() => {
            const prd_id = this.data[rowData.index].id
            this.$refs.productsOpeningBalance.$refs[`table_row_${prd_id}`][0].$refs['input_unitPrice'][0].$el.firstElementChild.focus()
          }, 10)
          break

        case 'newRow':
          this.$refs.productsOpeningBalance.$refs['table_row_0'][0].$refs['input_barcode'][0].$el.firstElementChild.focus()
          break
        }
      }, 100)
    },
    sendData () {

      let products = []
      this.data.forEach((product) => {
        if (product.id && product.id !== 0) {
          products.push({
            product_variant_id: product.id,
            unit_price: product.unitPrice.toString().replaceAll(',', ''),
            quantity: product.count
          })
        }
      })
      products = products.reverse()

      setStoreroomsOpeningInventories(this.storeroomId, products).then(response => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('products.openingBalance.notifications.insert.success'),
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather',
          time: 5000
        })

        this.$store.dispatch('removePageChanges')
        this.$store.dispatch('helper/changeOpeningBalance')
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          switch (error.response.status) {
          case 423:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('accountancy.openingBalance.validators.lock'),
              color: 'danger',
              time: 5000,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
            break

          default:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('products.openingBalance.notifications.insert.error'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 5000
            })
            break
          }
        }
      })
    },
    handleSendImportedFile () {
      if (this.importedDataFile.value) {
        let formData = new FormData()
        formData.append('imported_file', this.importedDataFile.value)

        importStoreroomsOpeningInventories(this.storeroomId, formData).then((response) => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('products.openingBalance.notifications.importData.success'),
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 5000
          })
          this.importDataPromptStatus = false
          this.$store.dispatch('removePageChanges')
          this.$store.dispatch('helper/changeOpeningBalance')
        })
          .catch(error => {

            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              switch (error.response.status) {
                case 423:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('accountancy.openingBalance.validators.lock'),
                    color: 'danger',
                    time: 5000,
                    icon: 'icon-alert-circle',
                    iconPack: 'feather'
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('products.openingBalance.notifications.importData.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 5000
                  })
                  break
              }
            }
          })
      }
    },
    handleReloadPrompt (value) {
      this.suggestPromptStatus = false
      setTimeout(() => {
        this.suggestPromptStatus = true
      }, 100)
    },
    checkOpeningBalanceStatus () {
      if (this.$store.state.helper.openingBalanceLocked) {
        const mapper = this.columnsLabel.map(elm => {
          return elm.field
        })

        const name_index = mapper.indexOf('name')
        this.columnsLabel[name_index].editable = false
        this.columnsLabel[name_index].suggest = false
        this.columnsLabel[name_index].suggestPrompt = false

        const count_index = mapper.indexOf('count')
        this.columnsLabel[count_index].editable = false

        const price_index = mapper.indexOf('unitPrice')
        this.columnsLabel[price_index].editable = false

        const action_index = mapper.indexOf('delete')
        this.columnsLabel.splice(action_index, 1)
      }
    }
  }
}
</script>

<style lang="scss">
.select-file-input {
  width: 100%;
  background: #28c76f;
  display: block;
  padding: 10px;
  border-radius: .5rem;

  span {
    color: white !important;

    div.icon {
      background-color: white !important;
    }
  }

  input {
    display: none;
  }
}

.products-opening-balance {
  height: 100%;

  .draggable-dynamic-table {
    .new-suggest-modal-button {
      display: none;
    }

    .suggest-modal-button {
      right: 7px !important;
    }
  }
}
</style>
