<template>
  <div class="treasury-select-cash-boxes-list">
    <draggable-dynamic-table ref="selectBanksList"
                             :data="data"
                             :columns="columnsLabel"
                             :options="options"
                             :in-modal="true"
                             @column:select="selectedRows = $event"
                             @load:more="getCashBoxes()"/>


    <vs-button id="selectCashBoxes" class="useral-action-button" @click="$emit('selected', selectedRows)"/>
  </div>
</template>

<script>
import axios from 'axios'
import {getCashBoxes} from '@/http/requests/cashBoxes'

export default {
  name: 'selectCashBoxes',
  props: {
    openingBalance: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedRows: [],
      insertPromptStatus: false,
      loadingTimer: 0,
      selectedCashBoxes: [],
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'treasury.cashBoxes.table.header.balance',
          hideCurrency: true,
          align: 'right',
          width: '130px',
          minWidth: 130,
          sortable: true,
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'name',
          i18n: 'treasury.cashBoxes.table.header.name',
          width: '170px',
          minWidth: 170,
          align: 'left',
          ellipsis: false,
          sortable: true,
          locked: true
        },
        {
          field: 'rowNumber',
          i18n: 'treasury.cashBoxes.table.header.row',
          width: '60px',
          minWidth: 60,
          maxWidth: 70,
          align: 'center',
          // sortable: true,
          fixed: true,
          locked: true,
          footer: {}
        },
        {
          field: 'select',
          i18n: 'treasury.cashBoxes.table.header.select',
          width: '50px',
          minWidth: 50,
          maxWidth: 50,
          align: 'center',
          selectable: true,
          fixed: true,
          locked: true
        }
      ],
      data: [],
      page: 1,
      endedList: false
    }
  },
  created () {
    this.getCashBoxes()
  },
  methods: {
    getCashBoxes () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.cashBoxesList && this.data.length === 0) this.$refs.cashBoxesList.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.cashBoxesList && this.data.length > 0) this.$refs.cashBoxesList.loadMoreStatus = 'Loading'

          getCashBoxes(this.page, {openingInventory: `${this.openingBalance}`}).then(response => {
            this.endedList = response.data.data.length === 0
            const cashBoxes = response.data.data
            cashBoxes.forEach((bank) => {
              this.data.push({
                id: bank.id,
                rowNumber: this.data.length + 1,
                name: bank.name,
                balance: {
                  type: 'price',
                  classes: bank.balance && bank.balance < 0 ? 'text-danger' : 'text-success',
                  value: bank.balance ? bank.balance : 0
                }
              })
            })
            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('rowNumber')
              this.columnsLabel[row_index].footer.value = response.data.pagination.total
            }

            if (this.$refs.cashBoxesList) this.$refs.cashBoxesList.loadMoreStatus = ''
          }).catch(error => {
            if (axios.isCancel(error)) {
              /*this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })*/
            } else if (this.$refs.cashBoxesList && !axios.isCancel(error)) this.$refs.cashBoxesList.loadMoreStatus = 'Danger'
          })
        }
      }, 300)
    }
  }
}
</script>

<style scoped>
.treasury-select-cash-boxes-list {
  height: 100%;
}
</style>
