// =============================================================================
// PURCHASE PAGES LAYOUTS
// =============================================================================

export default {
  path: '/purchases',
    component: () => import('../../views/admin/purchases/index.vue'),
  children: [
  {
    path: '/',
    redirect: '/error-404'
  },
  {
    path: 'invoices',
    component: () => import('../../views/admin/purchases/invoices/index.vue'),
    children: [
      {
        path: '/',
        name: 'purchasesInvoices',
        component: () => import(/* webpackChunkName: "purchasesInvoices" */ '../../views/admin/purchases/invoices/list/purchaseInvoicesList.vue'),
        meta: {
          permission: 'purchase_invoice.show',
          pageTitle: 'فاکتورهای خرید',
          pageTitleI18n: 'purchases.invoices.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'purchaseInvoicesTrash',
        component: () => import(/* webpackChunkName: "purchasesInvoices" */ '../../views/admin/purchases/invoices/trash/purchaseInvoicesTrash.vue'),
        meta: {
          permission: 'purchase_invoice.delete',
          pageTitle: 'سطل زباله فاکتور خرید',
          pageTitleI18n: 'purchases.invoices.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: 'suggest',
        name: 'suggestPurchaseInvoice',
        component: () => import(/* webpackChunkName: "suggestPurchasesInvoices" */ '../../views/admin/purchases/invoices/suggest/purchaseSuggest.vue'),
        meta: {
          permission: 'suggest_purchase_invoice.show',
          pageTitle: 'پیشنهاد فاکتور خرید',
          pageTitleI18n: 'purchases.invoices.suggest.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertPurchaseInvoice',
        component: () => import(/* webpackChunkName: "insertPurchaseInvoice" */ '../../views/admin/purchases/invoices/insert/insertPurchaseInvoice.vue'),
        meta: {
          permission: 'purchase_invoice.create',
          pageTitle: 'افزودن فاکتور خرید',
          pageTitleI18n: 'purchases.invoices.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        name: 'purchaseInvoice',
        component: () => import(/* webpackChunkName: "purchaseInvoice" */ '../../views/admin/purchases/invoices/invoice/purchaseInvoice.vue'),
        meta: {
          permission: 'purchase_invoice.show',
          pageTitle: 'فاکتور خرید',
          pageTitleI18n: 'purchases.invoices.invoice.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/activities-log',
        name: 'purchaseInvoiceActivitiesLog',
        component: () => import(/* webpackChunkName: "purchaseInvoiceActivitiesLog" */ '../../views/admin/purchases/invoices/activitiesLog/purchaseInvoiceActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های فاکتور خرید',
          pageTitleI18n: 'purchases.invoices.invoice.logs.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/edit',
        name: 'editPurchaseInvoice',
        component: () => import(/* webpackChunkName: "editPurchaseInvoice" */ '../../views/admin/purchases/invoices/edit/editPurchaseInvoice.vue'),
        meta: {
          permission: 'purchase_invoice.update',
          pageTitle: 'ویرایش فاکتور خرید',
          pageTitleI18n: 'purchases.invoices.edit.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/return',
        name: 'insertReturnPurchaseInvoice',
        component: () => import(/* webpackChunkName: "insertReturnPurchaseInvoice" */ '../../views/admin/purchases/returnPurchase/insert/insertReturnPurchaseInvoice.vue'),
        meta: {
          permission: ['purchase_invoice.show', 'purchase_invoice.update', 'return_purchase_invoice.create'],
          pageTitle: 'افزودن فاکتور برگشت از خرید',
          pageTitleI18n: 'purchases.returnInvoices.insert.title'
          // rule: 'editor'
        }
      }
    ]
  },
  {
    path: 'return-invoices',
    component: () => import('../../views/admin/purchases/returnPurchase/index.vue'),
    children: [
      {
        path: '/',
        name: 'returnPurchaseInvoices',
        component: () => import(/* webpackChunkName: "returnPurchaseInvoices" */ '../../views/admin/purchases/returnPurchase/list/returnPurchaseInvoicesList.vue'),
        meta: {
          permission: 'return_purchase_invoice.show',
          pageTitle: 'فاکتورهای مرجوع خرید',
          pageTitleI18n: 'purchases.returnInvoices.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'returnPurchaseInvoicesTrash',
        component: () => import(/* webpackChunkName: "purchasesInvoices" */ '../../views/admin/purchases/returnPurchase/trash/returnPurchaseInvoicesTrash.vue'),
        meta: {
          permission: 'return_purchase_invoice.delete',
          pageTitle: 'سطل زباله فاکتور مرجوع خرید',
          pageTitleI18n: 'purchases.returnInvoices.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        name: 'returnPurchaseInvoice',
        component: () => import(/* webpackChunkName: "returnPurchaseInvoice" */ '../../views/admin/purchases/returnPurchase/invoice/returnPurchaseInvoice.vue'),
        meta: {
          permission: 'return_purchase_invoice.show',
          pageTitle: 'فاکتور مرجوع خرید',
          pageTitleI18n: 'purchases.returnInvoices.invoice.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/activities-log',
        name: 'returnPurchaseInvoiceActivitiesLog',
        component: () => import(/* webpackChunkName: "returnPurchaseInvoiceActivitiesLog" */ '../../views/admin/purchases/returnPurchase/activitiesLog/returnPurchaseInvoiceActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های فاکتور مرجوع خرید',
          pageTitleI18n: 'purchases.returnInvoices.invoice.logs.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/edit',
        name: 'editReturnPurchaseInvoice',
        component: () => import(/* webpackChunkName: "editReturnPurchaseInvoice" */ '../../views/admin/purchases/returnPurchase/edit/editReturnPurchaseInvoice.vue'),
        meta: {
          permission: 'return_purchase_invoice.update',
          pageTitle: 'ویرایش فاکتور مرجوع خرید',
          pageTitleI18n: 'purchases.returnInvoices.edit.title'
          // rule: 'editor'
        }
      }
    ]
  }
]
}
