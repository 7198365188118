import store from "@/store/store";
import Vue from "vue";

const $t = sign => Vue.prototype.i18n.t(sign)
export function checkCloseModalHaveUnsavedChange () {
  if (store.state.pageHaveChanges) {
    const answer =
      window.confirm($t('alert.unsavedChanges'));

    if (answer) {
      store.dispatch('removePageChanges')
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export const modal = {
  checkHaveChange: checkCloseModalHaveUnsavedChange
}
