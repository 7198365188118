/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import moduleAuth from '@/store/auth/moduleAuth'
import moduleSetting from '@/store/setting/moduleSetting'
import moduleTable from '@/store/table/moduleTable'
import moduleHelper from '@/store/helper/moduleHelper'

Vue.use(Vuex)

let store

export default store || (store = new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    setting: moduleSetting,
    table: moduleTable,
    helper: moduleHelper
  },
  strict: process.env.NODE_ENV !== 'production'
}))
