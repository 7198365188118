<template>
  <div class="insert-attribute-value-box">
    <vs-row>
      <custom-validate-input class="w-full"
                             v-model="newAttributeValue.value"
                             :in-valid="$v.newAttributeValue.value.$invalid"
                             :autocomplete="false"
                             :name="Math.random()"
                             :label="$t('attributes.labels.value')"
                             @pressEnter="sendData"
                             @input="handleNameChange"/>

      <custom-validate-input class="w-full"
                             v-model="newAttributeValue.slug"
                             :in-valid="$v.newAttributeValue.slug.$invalid"
                             :autocomplete="false"
                             :name="Math.random()"
                             :label="$t('attributes.labels.slug')"
                             @pressEnter="sendData"/>
<!--      <vs-input class="attribute-value w-full"
                :label="$t('attributes.labels.value')"
                :danger="newAttributeValue.value.length > 30"
                :danger-text="newAttributeValue.value.length > 30 ? $t('attributes.validators.value', {num: 30}) : ''"
                v-model="newAttributeValue.value"
                :autocomplete="false"
                :name="Math.random()"
                @input="handleNameChange"/>
      <vs-input class="attribute-value w-full"
                :label="$t('attributes.labels.slug')"
                :autocomplete="false"
                :name="Math.random()"
                :danger="newAttributeValue.slug.length > 30"
                :danger-text="newAttributeValue.slug.length > 30 ? $t('attributes.validators.valueSlug', {num: 30}) : ''"
                v-model="newAttributeValue.slug"/>-->
    </vs-row>

    <button v-show="false"
            id="saveBTN"
            @click="sendData()"/>
  </div>
</template>

<script>
import CustomValidateInput from "../../../../../components/customInput/customValidateInput.vue";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  name: 'insertAttributeValue',
  components: {CustomValidateInput},
  props: {
    attributeId: {
      type: [Number, String],
      default: 0
    },
    shops: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      newAttributeValue: {
        value: {
          value: '',
          isValid: true
        },
        slug: {
          value: '',
          isValid: true
        }
      }
    }
  },
  validations: {
    newAttributeValue: {
      value: {
        value: {
          required,
          maxLength: maxLength(30)
        }
      },
      slug: {
        value: {
          required,
          maxLength: maxLength(30)
        }
      }
    }
  },
  methods: {
    handleNameChange () {
      this.newAttributeValue.slug.value = this.newAttributeValue.value.value.toLowerCase().replaceAll(' ', '-')
    },
    sendData () {
      if (!this.$v.newAttributeValue.value.value.maxLength) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.value', {num: 30}),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (!this.$v.newAttributeValue.slug.value.maxLength) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.valueSlug', {num: 30}),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }
      if (!this.$v.newAttributeValue.value.value.required) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.valueRequired'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (!this.$v.newAttributeValue.slug.value.required) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.slugRequired'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      this.$emit('inserted', this.newAttributeValue)
    }
  }
}
</script>

<style scoped>

</style>
