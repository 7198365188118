// =============================================================================
// EVENTS PAGES LAYOUTS
// =============================================================================

export default {
  path: '/events',
    component: () => import('../../views/admin/events/index.vue'),
  children: [
  {
    path: '/',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '../../views/admin/events/list/events.vue'),
    meta: {
      permission: 'event.show',
      pageTitle: '',
      pageTitleI18n: 'events.title'
      // rule: 'editor'
    }
  }
]
}
