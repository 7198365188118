<template>
  <div class="products-opening-balance">
    <draggable-dynamic-table ref="productsOpeningBalance"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             @row:clicked="handleRowClicked($event)"
                             @load:more="getOpeningBalance"
                             v-model="data"/>

    <!-- insert products opening balance prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertOpeningInventoryPromptStatus"
      @close="insertOpeningInventoryPromptStatus = !$u.modal.checkHaveChange()">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('products.openingBalance.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer flex">
            <div class="mr-2" @click="$u.click('importDataBTN')">
              <custom-icon icon="IMPORT" color="primary"/>
            </div>
            <div class="ml-2" @click="insertOpeningInventoryPromptStatus = !$u.modal.checkHaveChange()">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <insert-products-opening-balance :storeroom-id="storeroomId"/>
      </div>

    </vs-prompt>

    <!-- edit products opening balance prompt -->
    <vs-prompt
      class="p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editOpeningInventoryPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 edit-products-opening-balance-prompt-title text-center useral-font-weight-bold text-md">

            {{ selected.name || '' }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editOpeningInventoryPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <edit-products-opening-balance :storeroom-id="storeroomId" :product="selected"/>
      </div>

    </vs-prompt>

    <button v-show="false" id="InsertOpeningInventoryBTN" @click="insertOpeningInventoryPromptStatus = true"/>
  </div>
</template>

<script>
import axios from 'axios'
import SelectProducts from '@/views/admin/products/productsList/select/selectProducts'
import InsertProduct from '@/views/admin/products/productsList/insert/insertProduct'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import InsertProductsOpeningBalance from './insertProductsOpeningBalance'
import EditProductsOpeningBalance from './editProductsOpeningBalance'
import {
  getStoreroomsOpeningInventories,
  getStoreroomsProductOpeningInventories
} from '../../../../../http/requests/openingInventories'

export default {
  name: 'productsOpeningBalance',
  components: {EditProductsOpeningBalance, InsertProductsOpeningBalance, CustomIcon, InsertProduct, SelectProducts},
  props: {
    storeroomId: {
      type: [Number, String],
      default: 0,
      validator (value) {
        return parseInt(value || 0) >= 0
      }
    }
  },
  data () {
    return {
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        /*{
          field: 'delete',
          headerName: 'حذف',
          width: '70px',
          minWidth: 70,
          /!*sortable: true,*!/
          actions: true,
          showAction: 'always',
          action: {
            color: 'danger',
            icon: 'TRASH',
            iconPack: 'useral',
            type: 'button'
          },
          event: 'row:deleted'
        },*/
        {
          field: 'totalPrice',
          i18n: 'products.openingBalance.table.header.totalPrice',
          hideCurrency: true,
          align: 'right',
          width: '150px',
          minWidth: 150,
          /*sortable: true,*/
          /*editable: true,*/
          type: 'relation',
          relation: '(count)[*](unitPrice)',
          relationType: 'price',
          footer: {
            // type: 'auto-sum',
            // textType: 'price'
          }
        },
        {
          field: 'unitPrice',
          i18n: 'products.openingBalance.table.header.unitPrice',
          hideCurrency: true,
          align: 'right',
          width: '150px',
          minWidth: 150,
          valueType: 'price'
        },
        {
          field: 'count',
          i18n: 'products.openingBalance.table.header.count',
          width: '70px',
          minWidth: 70,
          align: 'center'
        },
        {
          field: 'variant',
          i18n: 'products.openingBalance.table.header.variant',
          width: 'calc(25%)',
          minWidth: 150
          /*sortable: true,*/
          // dropDown: true
        },
        {
          field: 'name',
          i18n: 'products.openingBalance.table.header.name',
          align: 'left',
          width: 'calc(75%)',
          minWidth: 500
        },
        {
          field: 'barcode',
          i18n: 'products.openingBalance.table.header.barcode',
          align: 'center',
          width: '150px',
          minWidth: 150,
          footer: {}
        },
        {
          field: 'rowNumber',
          i18n: 'products.openingBalance.table.header.rowNumber',
          align: 'center',
          width: '70px',
          minWidth: 70,
          fixed: true,
          footer: {}
        }
      ],
      selected: {},
      data: [],
      productsList: [],
      filters: {},
      suggests: {
        name: []
      },
      loadingTimer: 0,
      total_count: 0,
      page: 1,
      insertOpeningInventoryPromptStatus: false,
      editOpeningInventoryPromptStatus: false
    }
  },
  created () {
    this.getOpeningBalance()
    this.checkOpeningBalanceStatus()
  },
  methods: {
    getOpeningBalance () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (this.$refs.productsOpeningBalance && this.data.length === 0) this.$refs.productsOpeningBalance.loadMoreStatus = 'FirstLoad'
        else if (this.$refs.productsOpeningBalance && this.data.length > 0) this.$refs.productsOpeningBalance.loadMoreStatus = 'Loading'

        if (this.data.length < this.total_count || !this.total_count) {
          getStoreroomsOpeningInventories(this.storeroomId, this.page).then(response => {
            const products = response.data.data

            this.total_count = response.data.pagination.total

            products.forEach((product) => {
              const variables = product.variables.map(elm => {
                return `${elm.name.replaceAll('-', ' ')}: ${elm.value.replaceAll('-', '/')}`
              })
              const data = {
                rowNumber: this.total_count - this.data.length,
                id: product.id,
                product_id: product.product_id,
                barcode: product.barcode,
                name: product.product.title,
                variant: variables.length > 0 ? variables.join(', ') : '-',
                variant_id: product.id,
                count: product.quantity,
                unitPrice: this.$u.priceFormat(product.price),
                totalPrice: {value: product.price * product.quantity, type: 'price'},
                flag: ''
              }
              this.data.push(data)
            })
            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('rowNumber')
              this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
            }

            const price_index = this.columnsLabel.map((e) => {
              return e.field
            }).indexOf('totalPrice')
            if (price_index > -1) {
              this.columnsLabel[price_index].footer.value = this.$u.priceFormat(response.data.extra.total_price, true)
            }

            if (this.$refs.productsOpeningBalance) this.$refs.productsOpeningBalance.loadMoreStatus = ''
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              if (this.$refs.productsOpeningBalance && !axios.isCancel(error)) this.$refs.productsOpeningBalance.loadMoreStatus = 'Danger'
            }
          })
        } else {
          if (this.$refs.productsOpeningBalance) this.$refs.productsOpeningBalance.loadMoreStatus = ''
        }

      }, 300)
    },
    setFilter (filters) {
      clearTimeout(this.isTypingTimer)
      this.isTypingTimer = setTimeout(() => {
        const filters_list = {}
        switch (filters.param) {

        case 'code':
          if (filters.value !== '') filters_list.code = `${filters.value},1`
          break

        case 'category':
          if (filters.value !== '') filters_list.category = `${filters.value},1`
          break

        case 'name':
          if (filters.value !== '') filters_list.title = `${filters.value},1`
          break

        case 'type':
          filters_list.type = `${filters.value},1`
          break
        }

        this.suggests.name = []
        this.filters = filters_list
        this.getProducts()
      }, 500)
    },
    handleRowClicked (item) {
      if (!this.$store.state.helper.openingBalanceLocked) {
        this.selected = item
        this.editOpeningInventoryPromptStatus = true
      }
    },
    checkOpeningBalanceStatus () {
      if (this.$store.state.helper.openingBalanceLocked) {
        const mapper = this.columnsLabel.map(elm => {
          return elm.field
        })

        const name_index = mapper.indexOf('name')
        this.columnsLabel[name_index].editable = false
        this.columnsLabel[name_index].suggest = false
        this.columnsLabel[name_index].suggestPrompt = false

        const count_index = mapper.indexOf('count')
        this.columnsLabel[count_index].editable = false

        const price_index = mapper.indexOf('unitPrice')
        this.columnsLabel[price_index].editable = false

        /*const action_index = mapper.indexOf('delete')
        this.columnsLabel.splice(action_index, 1)*/
      }
    },
    refreshProductVariantDetails () {
      getStoreroomsProductOpeningInventories(this.storeroomId, this.selected.id).then(response => {
        const stock = response.data.data
        const stock_index = this.data.map(elm => { return elm.barcode }).indexOf(stock.barcode)
        if (stock_index >= 0) {
          const variables = stock.variables.map(elm => {
            return `${elm.name.replaceAll('-', ' ')}: ${elm.value.replaceAll('-', '/')}`
          })
          this.data[stock_index] = {
            rowNumber: this.data[stock_index].rowNumber,
            id: stock.id,
            product_id: stock.product_id,
            barcode: stock.barcode,
            name: stock.product.title,
            variant: variables.length > 0 ? variables.join(', ') : '-',
            variant_id: stock.id,
            count: stock.quantity,
            unitPrice: this.$u.priceFormat(stock.price),
            totalPrice: {value: stock.price * stock.quantity, type: 'price'},
            flag: ''
          }

          if (this.$refs.productsOpeningBalance) this.$refs.productsOpeningBalance.setTableData()
        }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    deleteProductVariantDetails () {
      const stock_index = this.data.map(elm => { return elm.barcode }).indexOf(this.selected.barcode)
      if (stock_index >= 0) this.data.splice(stock_index, 1)

      getStoreroomsOpeningInventories(this.storeroomId, 1).then(response => {
        if (response.data.pagination.current_page === 1) {
          const row_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('rowNumber')
          this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
        }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })

      if (this.$refs.productsOpeningBalance) this.$refs.productsOpeningBalance.setTableData()
    }
  },
  watch: {
    '$store.state.helper.openingBalanceChanged': {
      handler (val) {
        if (val) {
          if (this.insertOpeningInventoryPromptStatus) {
            this.page = 1
            this.data = []
            this.$nextTick(() => {
              this.getOpeningBalance()
            })
          }

          if (this.editOpeningInventoryPromptStatus && this.selected) this.refreshProductVariantDetails()

          this.insertOpeningInventoryPromptStatus = false
          this.editOpeningInventoryPromptStatus = false
        }
      },
      deep: true
    },
    '$store.state.helper.openingBalanceDeleted': {
      handler (val) {
        if (val) {
          if (this.editOpeningInventoryPromptStatus && this.selected) this.deleteProductVariantDetails()
          this.editOpeningInventoryPromptStatus = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.select-file-input {
  width: 100%;
  background: #28c76f;
  display: block;
  padding: 10px;
  border-radius: .5rem;

  span {
    color: white !important;

    div.icon {
      background-color: white !important;
    }
  }

  input {
    display: none;
  }
}

.edit-products-opening-balance-prompt-title {
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.products-opening-balance {
  height: 100%;
}
</style>
