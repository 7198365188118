// =============================================================================
// SALES PAGES LAYOUTS
// =============================================================================

export default {
  path: '/sales',
    component: () => import('../../views/admin/sales/index.vue'),
  children: [
  {
    path: '/',
    redirect: '/error-404'
  },
  {
    path: 'invoices',
    component: () => import('../../views/admin/sales/invoices/index.vue'),
    children: [
      {
        path: '/',
        name: 'saleInvoices',
        component: () => import(/* webpackChunkName: "saleInvoices" */ '../../views/admin/sales/invoices/list/saleInvoicesList.vue'),
        meta: {
          permission: 'sale_invoice.show',
          pageTitle: '',
          pageTitleI18n: 'sales.invoices.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'saleInvoiceTrash',
        component: () => import(/* webpackChunkName: "usersTrash" */ '../../views/admin/sales/invoices/trash/saleInvoicesTrash.vue'),
        meta: {
          permission: 'sale_invoice.delete',
          pageTitle: 'سطل زباله فاکتور فروش',
          pageTitleI18n: 'sales.invoices.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: 'suggest',
        name: 'suggestSaleInvoice',
        component: () => import(/* webpackChunkName: "suggestSaleInvoice" */ '../../views/admin/sales/invoices/suggest/saleSuggest.vue'),
        meta: {
          permission: 'suggest_sale_invoice.show',
          pageTitle: 'پیشنهاد فروش',
          pageTitleI18n: 'sales.invoices.suggest.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertSaleInvoice',
        component: () => import(/* webpackChunkName: "insertSaleInvoice" */ '../../views/admin/sales/invoices/insert/insertSaleInvoice.vue'),
        meta: {
          permission: 'sale_invoice.create',
          pageTitle: '',
          pageTitleI18n: 'sales.invoices.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        name: 'saleInvoice',
        component: () => import(/* webpackChunkName: "saleInvoice" */ '../../views/admin/sales/invoices/invoice/saleInvoice.vue'),
        meta: {
          permission: 'sale_invoice.show',
          pageTitle: '',
          pageTitleI18n: 'sales.invoices.invoice.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/activities-log',
        name: 'saleInvoiceActivitiesLog',
        component: () => import(/* webpackChunkName: "saleInvoiceActivitiesLog" */ '../../views/admin/sales/invoices/activitiesLog/saleInvoiceActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های فاکتور فروش',
          pageTitleI18n: 'sales.invoices.invoice.logs.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/edit',
        name: 'editSaleInvoice',
        component: () => import(/* webpackChunkName: "editSaleInvoice" */ '../../views/admin/sales/invoices/edit/editSaleInvoice.vue'),
        meta: {
          permission: 'sale_invoice.update',
          pageTitle: '',
          pageTitleI18n: 'sales.invoices.edit.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/return',
        name: 'insertReturnSaleInvoice',
        component: () => import(/* webpackChunkName: "insertReturnSaleInvoice" */ '../../views/admin/sales/returnInvoices/insert/insertReturnSaleInvoice.vue'),
        meta: {
          permission: ['sale_invoice.show', 'sale_invoice.update', 'return_sale_invoice.create'],
          pageTitle: '',
          pageTitleI18n: 'sales.returnInvoices.insert.title'
          // rule: 'editor'
        }
      }
    ]
  },
  {
    path: 'return-invoices',
    component: () => import('../../views/admin/sales/returnInvoices/index.vue'),
    children: [
      {
        path: '/',
        name: 'returnSaleInvoices',
        component: () => import(/* webpackChunkName: "returnSaleInvoices" */ '../../views/admin/sales/returnInvoices/list/returnSaleInvoicesList.vue'),
        meta: {
          permission: 'return_sale_invoice.show',
          pageTitle: '',
          pageTitleI18n: 'sales.returnInvoices.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'returnSaleInvoicesTrash',
        component: () => import(/* webpackChunkName: "returnSaleInvoices" */ '../../views/admin/sales/returnInvoices/trash/returnSaleInvoicesTrash.vue'),
        meta: {
          permission: 'return_sale_invoice.delete',
          pageTitle: '',
          pageTitleI18n: 'sales.returnInvoices.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        name: 'returnSaleInvoice',
        component: () => import(/* webpackChunkName: "returnSaleInvoice" */ '../../views/admin/sales/returnInvoices/invoice/returnSaleInvoice.vue'),
        meta: {
          permission: 'return_sale_invoice.show',
          pageTitle: '',
          pageTitleI18n: 'sales.returnInvoices.invoice.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/activities-log',
        name: 'returnSaleInvoiceActivitiesLog',
        component: () => import(/* webpackChunkName: "returnSaleInvoiceActivitiesLog" */ '../../views/admin/sales/returnInvoices/activitiesLog/returnSaleInvoiceActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های فاکتور مرجوع فروش',
          pageTitleI18n: 'sales.returnInvoices.invoice.logs.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id/edit',
        name: 'editReturnSaleInvoice',
        component: () => import(/* webpackChunkName: "editReturnSaleInvoice" */ '../../views/admin/sales/returnInvoices/edit/editReturnSaleInvoice.vue'),
        meta: {
          permission: 'return_sale_invoice.update',
          pageTitle: '',
          pageTitleI18n: 'sales.returnInvoices.edit.title'
          // rule: 'editor'
        }
      }
    ]
  },
  {
    path: 'pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '../../views/admin/sales/productPricing/productPricing.vue'),
    name: 'productsPricing',
    meta: {
      pageTitle: 'قیمت گذاری محصولات',
      pageTitleI18n: 'products.pricing.title'
      // rule: 'editor'
    }
  },
  {
    path: 'groups',
    component: () => import('../../views/admin/sales/groups/index'),
    children: [
      {
        path: '/',
        name: 'saleGroupsList',
        component: () => import(/* webpackChunkName: "saleGroupsList" */ '../../views/admin/sales/groups/list/saleGroupsList.vue'),
        meta: {
          pageTitle: 'لیست گروه های فروش',
          pageTitleI18n: 'sales.groups.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertSaleGroup',
        component: () => import(/* webpackChunkName: "insertSaleGroup" */ '../../views/admin/sales/groups/insert/insertSaleGroup.vue'),
        meta: {
          pageTitle: 'افزودن گروه فروش جدید',
          pageTitleI18n: 'sales.groups.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: 'grp-:id/edit',
        name: 'editSaleGroup',
        component: () => import(/* webpackChunkName: "editSaleGroup" */ '../../views/admin/sales/groups/edit/editSaleGroup.vue'),
        meta: {
          pageTitle: 'ویرایش گروه فروش',
          pageTitleI18n: 'sales.groups.edit.title'
          // rule: 'editor'
        }
      }
    ]
  }
]
}
